<template>
  <div class="my_calculation">
    <div class="init_main">
      <div class="present_header">物流费用计算</div>
      <!-- 填写信息部分 start -->
      <div class="present_box_2 flex_column_start_center">
        <div class="present_box_inp flex_row_start_center">
          <p><span>*</span>发货地：</p>
          <!-- <el-input v-model="input" placeholder="请输入单位名称" class="inp" /> -->
          <el-select
            v-model="orginPlace"
            placeholder="请选择发货地"
            size="large"
            class="inp"
            @change="changes"
          >
            <el-option
              v-for="item in shipment.data"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div class="present_box_inp flex_row_start_center">
          <p><span>*</span>卸货地：</p>
          <!-- <el-input v-model="input" placeholder="请输入单位名称" class="inp" /> -->
          <!-- <el-select
            v-model="cityCode"
            placeholder="请选择卸货地"
            size="large"
            class="inp"
          >
            <el-option
              v-for="(item,index) in cityList.data"
              :key="index"
              :label="item.name"
              :value="item.code"
            />
          </el-select> -->
          <el-cascader  class="inp" style="width: 450px" ref="cascaderAddr" @change="handleChange" v-model="areaList.data" :options="cityList.data" placeholder="请选择卸货地"
            :props="cascaderProps"></el-cascader>
        </div>
        <div class="present_box_inp flex_row_start_center">
          <p><span>*</span>吨位：</p>
          <el-input
            v-model="number"
            type="number"
            placeholder="请输入您需要的吨位"
            class="inp"
            @input="numFour"
          />
        </div>
        <div class="present_box_inp flex_row_start_center" style="margin-bottom: 10px;">
          <p>运费：</p>
          <el-input
            v-model="calculate"
            type="number"
            placeholder=""
            class="inp_o"
          />
          元/吨
        </div>
        <div class="present_box_inp flex_row_start_center">
          <p style="width: 17px;"></p>
          <div style="color: rgba(123, 114, 114, 1);">此运费价格为标载参考价，如有特殊需求请与我联系0412-2598228</div>
        </div>
      </div>
      <!-- 填写信息部分 end -->
      <div class="present_btn flex_row_center_center" @click="calcul">开始计算</div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, toRefs, getCurrentInstance, onMounted } from "vue";
import { inqUtils } from "@/utils/inquiry_utils.js";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
export default {
  setup(props) {
    const { proxy } = getCurrentInstance();
    const {
      uploadFile,
      fileList,
      clearFileList,
      unitList,
      formatTime,
      setFileDTO,
    } = inqUtils();
    const number = ref('');//吨
    const calculate = ref('');//运费
    const orginPlace = ref('1');//发货地
    const cityCode = ref('');//卸货地
    const areaList = reactive({
         data:[]
      })
    const shipment = reactive({
      data:[
      {
        value: '1',
        label: '沈阳',
      },
      {
        value: '2',
        label: '鞍山',
      },
      ]
    })
    const cascaderProps = { label: "regionName", value: "regionCode" };
    const cityList = reactive({//目的地
      data:[]
    }) 

    const numFour = ()=>{
      if(number.value<0){
          number.value=0
        }
        let num = String(number.value).split('.')
        let nums = 0
        if(num[1]&&num[1].length>2){
          num[1] = num[1].substring(0,2)
          nums = num.join('.')
          number.value = Number(nums)
        }else{
            nums = number.value
         }
    }

    // 运费获取目的地
    const getDestination = ()=>{
      let param = {
        presencePlace:orginPlace.value==1?'沈阳':'鞍山'
      }
      proxy.$get('v3/business/front/orderOperate/addressList',param).then(res => {
        if (res.state == 200) {
            cityList.data = res.data
        } else {
              ElMessage.error(res.msg)
        }
      })
    }
    const handleChange = (e)=>{
         cityCode.value = areaList.data[2]
      }
    // 计算
    const calcul =()=>{
      if(!cityCode.value){
        ElMessage('请选择卸货地')
        return
      }
      if(!number.value){
        ElMessage('请输入吨位')
        return
      }
      let param = {
        orginPlace:orginPlace.value,
        number:number.value,
        cityCode:cityCode.value
      }
      proxy.$get('v3/business/front/orderOperate/calculateExpressFee',param).then(res => {
        if (res.state == 200) {
          ElMessage.success('运费计算成功')
          calculate.value = res.data
        } else {
              // inqDTO.receiverAddress = inqDTO.receiverAddress.split('/')
              ElMessage.error(res.msg)
        }
      })
    }

    const changes = ()=>{
      cityCode.value = ''
      getDestination()
    }

    onMounted(() => {
      getDestination()
    });

    return {
      fileList,
      clearFileList,
      number,
      shipment,
      orginPlace,
      changes,
      cityList,
      calculate,
      cityCode,
      numFour,
      calcul,
      areaList,
      cascaderProps,
      handleChange,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/logisticsCalculation.scss";
@import "../../style/base.scss";
</style>
<style lang="scss">
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
    -moz-appearance: textfield;
  }
input {
  height: 36px;
  outline: none;
  border: none;
}
.my_present {
  textarea {
    resize: none !important;
    height: 100px !important;
  }
}

// .my_inquiry {
//      .is-active {
//           color: $colorMain;
//      }

//      .el-tabs__active-bar {
//           background-color: $colorMain;
//      }

//      .el-tabs__item:hover {
//           color: $colorMain;
//           cursor: pointer;
//      }

//      .el-date-editor--daterange.el-input__inner {
//           width: 250px;
//      }

//      .el-date-editor .el-range-separator {
//           width: 7%;
//      }

//      .wrap6 .el-input__inner {
//           border: none;
//      }

//      .has-gutter tr th {
//           background: #F7F8FA;
//           color: #101010;
//      }
// }
</style>

